import ButtonComponent from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import RichContent from '@pretto/bricks/website/about/components/RichContent'
import BaseImage from '@pretto/bricks/website/shared/components/Image'
import BaseReasonToBelieve from '@pretto/bricks/website/shared/components/ReasonToBelieve'

import styled from 'styled-components'

export const Description = styled(RichContent)`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(4)};
  }
`
export const Button = styled(ButtonComponent)`
  margin-top: ${g(3)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: fit-content;
  }
`
export const Image = styled(BaseImage).attrs({ options: { height: ng(36), width: ng(36) } })`
  display: block;
  height: ${g(25)};
  width: ${g(25)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    height: ${g(36)};
    width: ${g(36)};
  }
`
export const ImageContainer = styled.div`
  ${column([1, 3])};
  align-items: center;
  display: flex;
  grid-row: 1;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 4])};
  }
`
export const Main = styled.div`
  ${column([2, 4])};
  padding: ${g(4)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([2, 4]) : column([2, 5]))}
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([2, 5]) : column([2, 6]))}
    padding: ${({ $isButtonEnabled }) => ($isButtonEnabled ? g(5) : g(9))} 0;
  }
`
export const MiniSentencesDesktop = styled.div`
  ${column([1, 3])};
  padding: ${g(3)} 0px;
  align-items: center;
  display: flex;
  grid-row: 1;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 5])};
  }
`

export const MiniSentencesMobile = styled.div`
  ${column([2, 4])};
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const Shape = styled.div`
  ${column([2, 3])};
  background-color: ${({ theme }) => theme.colors.primary2};
  border-bottom-left-radius: ${g(29)};
  grid-row: 1;
  margin-bottom: ${g(-5)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 5])};
  }
`
export const ReasonToBelieve = styled(BaseReasonToBelieve)`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`
export const Side = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([6, 4]) : column([7, 4]))}
    ${grid(3, { isLeftAligned: true })};
    grid-template-rows: 1fr;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ $isSimulatorDisplayed }) => ($isSimulatorDisplayed ? column([8, 6]) : column([9, 6]))}
    ${grid(5, { isLeftAligned: true })};
  }
`
export const Title = styled(BaseTypo).attrs({ forwardedAs: 'h1', typo: 'heading48' })``
