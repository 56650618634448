import { Grid } from '@pretto/bricks/components/layout/Grid'
import MiniSentences from '@pretto/bricks/website/shared/components/MiniSentences'

import { useSentences } from '@pretto/website/src/utilities'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const HeroN1 = ({
  buttonLabel,
  buttonUrl,
  checks,
  description,
  image,
  isButtonEnabled,
  isCheckSectionDisplayed,
  isPromisesEnabled,
  isSimulatorDisplayed,
  projectKind,
  promises,
  simulatorTabs,
  title,
  ...props
}) => {
  const sentencesProps = useSentences({ checks, tabs: projectKind, tabsData: simulatorTabs })
  const miniSentencesProps = { ...sentencesProps, isCheckSectionDisplayed }

  const miniSentencesComponent = <MiniSentences {...miniSentencesProps} />

  return (
    <Grid {...props}>
      <S.Main $isButtonEnabled={isButtonEnabled} $isSimulatorDisplayed={isSimulatorDisplayed}>
        <S.Title>{title}</S.Title>
        <S.Description allowedTypes={['strong']}>{description}</S.Description>
        {isButtonEnabled && <S.Button href={buttonUrl}>{buttonLabel}</S.Button>}
      </S.Main>

      <S.Side $isSimulatorDisplayed={isSimulatorDisplayed}>
        <S.Shape />

        {isSimulatorDisplayed ? (
          <S.MiniSentencesDesktop>{miniSentencesComponent}</S.MiniSentencesDesktop>
        ) : (
          <S.ImageContainer>
            <S.Image path={image} />
          </S.ImageContainer>
        )}
      </S.Side>

      {isSimulatorDisplayed && <S.MiniSentencesMobile>{miniSentencesComponent}</S.MiniSentencesMobile>}

      {isPromisesEnabled && <S.ReasonToBelieve promises={promises} />}
    </Grid>
  )
}

HeroN1.propTypes = {
  /** Label of the button */
  buttonLabel: PropTypes.string,
  /** Url of the button */
  buttonUrl: PropTypes.string,
  /** List of the checks in the simulator. */
  checks: PropTypes.array,
  /** Sub Text of Hero */
  description: PropTypes.string.isRequired,
  /** Path of illustration */
  image: PropTypes.string.isRequired,
  /** Whether or the button is visible */
  isButtonEnabled: PropTypes.bool.isRequired,
  /** Is the check section displayed in the simulator ? */
  isCheckSectionDisplayed: PropTypes.bool,
  /** Whether or the RTB is visible */
  isPromisesEnabled: PropTypes.bool.isRequired,
  /** Is simulator displayed ? */
  isSimulatorDisplayed: PropTypes.bool,
  /** Promises */
  promises: PropTypes.array,
  /** List of the projectKinds in the simulator tabs */
  projectKind: PropTypes.array,
  /** Data about the simulator tabs. */
  simulatorTabs: PropTypes.array,
  /** Title of element */
  title: PropTypes.string.isRequired,
}

export default memo(HeroN1)
